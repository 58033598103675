html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  max-width: 100%;
  white-space: normal;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

.Hr3ysa_ColorText {
  background: linear-gradient(75deg, #1fe0da 25.16%, #f532a7 60.98%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

._3tWDeq_SequencerStep {
  flex-direction: column;
  display: flex;
}

input._3tWDeq_SequencerStep[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  font: inherit;
  color: currentColor;
  width: 69px;
  height: 54px;
  background-color: #9ca19f;
  border: .5px solid #121212;
  border-radius: 8px;
  flex-grow: 1;
  align-content: center;
  justify-content: center;
  margin: 0;
  display: flex;
  -webkit-transform: translateY(-.075em);
  transform: translateY(-.075em);
}

@media (hover: hover) {
  input._3tWDeq_SequencerStep[type="checkbox"]:hover {
    background-color: #ccd7d5;
  }

  input._3tWDeq_SequencerStep._3tWDeq_playing:hover[type="checkbox"] {
    background-color: #e0349b;
  }

  input._3tWDeq_SequencerStep._3tWDeq_muted._3tWDeq_playing:hover[type="checkbox"] {
    background-color: #9aa5a0;
  }

  input._3tWDeq_SequencerStep._3tWDeq_muted:hover[type="checkbox"] {
    background-color: #9aa5a0;
  }

  input._3tWDeq_SequencerStep._3tWDeq_muted:hover[type="checkbox"]:checked:before {
    background-color: #0b4240;
  }

  input._3tWDeq_SequencerStep[type="checkbox"]:checked:hover:before {
    background-color: #159b96;
  }

  input._3tWDeq_SequencerStep._3tWDeq_playing[type="checkbox"]:checked:hover:before {
    background-color: #aa2274;
  }

  input._3tWDeq_SequencerStep._3tWDeq_muted._3tWDeq_playing[type="checkbox"]:checked:hover:before {
    background-color: #400b2b;
  }
}

input._3tWDeq_SequencerStep._3tWDeq_playing[type="checkbox"] {
  background-color: #a62673;
}

input._3tWDeq_SequencerStep._3tWDeq_muted._3tWDeq_playing[type="checkbox"] {
  background-color: #4d1436;
}

input._3tWDeq_SequencerStep._3tWDeq_muted[type="checkbox"] {
  background-color: #4c514f;
}

input._3tWDeq_SequencerStep._3tWDeq_muted[type="checkbox"]:checked:before {
  background-color: #116764;
}

input._3tWDeq_SequencerStep[type="checkbox"]:checked:before {
  background-color: #1fe0da;
}

input._3tWDeq_SequencerStep._3tWDeq_playing[type="checkbox"]:checked:before {
  background-color: #f532a7;
}

input._3tWDeq_SequencerStep._3tWDeq_muted._3tWDeq_playing[type="checkbox"]:checked:before {
  background-color: #6e134a;
}

input._3tWDeq_SequencerStep[type="checkbox"]:before {
  content: "";
  width: 68px;
  height: 53px;
  border-radius: 8px;
}

.tnRwIG_AudioWorkstation {
  color: #cbcbcb;
  background-color: #121212;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-ext-700-normal.0921acac.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-cyrillic-700-normal.96126479.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-ext-700-normal.00d528bb.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-greek-700-normal.bc3ad99c.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-vietnamese-700-normal.a1447a12.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-ext-700-normal.372a1858.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url("roboto-latin-700-normal.0e98339e.woff2") format("woff2"), url("roboto-all-700-normal.23db685f.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-ext-500-normal.216ff506.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-cyrillic-500-normal.4c9891c0.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-ext-500-normal.63a0634f.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-greek-500-normal.9f44a402.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-vietnamese-500-normal.11348a9c.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-ext-500-normal.922e2b56.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("roboto-latin-500-normal.da1d77f1.woff2") format("woff2"), url("roboto-all-500-normal.6bf43474.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-ext-400-normal.5baf44ce.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-cyrillic-400-normal.6d360dd5.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-ext-400-normal.3b199b49.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-greek-400-normal.94705851.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-vietnamese-400-normal.b7170924.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-ext-400-normal.65ba6c38.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url("roboto-latin-400-normal.57844c26.woff2") format("woff2"), url("roboto-all-400-normal.2cd42759.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-ext-300-normal.8895cd13.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+460-52F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-cyrillic-300-normal.303b9afa.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+301, U+400-45F, U+490-491, U+4B0-4B1, U+2116;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-ext-300-normal.73ef051e.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+1F??;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-greek-300-normal.962b7627.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+370-3FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-vietnamese-300-normal.6fb53ea7.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+102-103, U+110-111, U+128-129, U+168-169, U+1A0-1A1, U+1AF-1B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-ext-300-normal.51d7b0c4.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+100-24F, U+259, U+1E??, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: Roboto;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("roboto-latin-300-normal.d07a8413.woff2") format("woff2"), url("roboto-all-300-normal.881b4525.woff") format("woff");
  unicode-range: U+??, U+131, U+152-153, U+2BB-2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus input:-webkit-autofill {
  -webkit-text-fill-color: white;
  transition: background-color 5000s ease-in-out;
  box-shadow: inset 0 0 0 1000px #000;
}

/*# sourceMappingURL=index.2fa82d00.css.map */
